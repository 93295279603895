import React from 'react'
import ClientCard from 'components/client-card'
import PropTypes from 'prop-types'
import { window } from 'browser-monads'
import { woodwatch_1, woodwatch_2, woodwatch_3, bibbr_2, avy_2, yummygum_1, yummygum_2, yummygum_3, yummygum_4 } from 'images/klanten'
import { avy_logo, bibbr_logo } from 'images/klanten'
import Layout from '../../layouts'
import Helmet from 'react-helmet'

const dummyKlantVerhaal = {
  pageTitle: '“Sneller salarisadministratie verwerkt zonder gedoe.”',
  pageStreamer: 'Yummygum te Amsterdam, Vince Schwidder',
  useCase: '15 werknemers',
  person: {
    name: 'Vince Schwidder',
    functionTitle: 'Mede-oprichter',
    description: 'Sinds 2009 creëren we bij Yummygum digitale producten voor het web en mobiele apparaten.',
    portraitImage: yummygum_1,
    portraitImageSmall: yummygum_2,
    internallink: false,
    link: 'https://www.yummygum.com',
    btnText: 'Bekijk website&nbsp;→'
  },
  content: [{
    title: 'Bedrijf',
    description: 'In 2009 hebben Leon Ephraïm en Vince Schwidder hun bedrijf Yummygum opgericht. Het startte als een fusering van 2 eenmanszaken van 2 goede vrienden, en is inmiddels gegroeid tot een team van 15 werknemers. Hun missie is om het digitale leven een stukje mooier, bruikbaarder en fijner te maken. Hun oog voor detail en effectieve proces zorgt voor een indrukwekkende portfolio van digitale producten voor startups, MKB bedrijven en grote Amerikaanse namen.<br/><br/>We spraken remote met Vince Schwidder, mede-oprichter van Yummygum. Hij vertelt over zijn ervaring met personeel, administratie en de rol van Employes in het bedrijf.'
    }, {
      title: 'Personeel',
      description: 'Vince: “Na een aantal jaar met z’n tweeën te hebben gewerkt, merkten we de behoefte om ons team uit te breiden. Als je 3 à 4 werknemers hebt, is er nog niet heel veel administratie en kan je veel handmatig doen. Toen we echter doorgroeiden naar ongeveer 8 werknemers werd het duidelijk dat dit niet schaalbaar is.”<br/><br/>Destijds hebben we ook onze boekhouder ingezet om extra taken op zich te nemen omtrent salarisadministratie. Maar dit werkte ook niet lekker voor ons omdat wij meer wilden automatiseren, makkelijker rapportages wilden draaien en vooral minder tijd willen spenderen aan taken omtrent salarisadministratie. In 2019 zijn we gestart met het gebruik van Employes en de tool helpt ons al die doelen te bereiken.”'
    }, {
      image: yummygum_3
    },{
      title: 'Waarom gekozen voor Employes?',
      description: 'Vince: “Een intuïtieve interface en intentioneel beperkte feature set trok ons over de streep om Employes te blijven gebruiken. Veel tools willen een alles-in-een oplossing zijn, maar dat resulteert vaak in een onderontwikkeld platform op de plekken waar je juist wilt dat het best-in-class is.”<br/><br/>Je hebt dus ook gekeken naar andere tools? Vince: “Absoluut, er is best veel aanbod op dit gebied. Snel werd ons wel duidelijk dat we geen gigantisch softwarepakket wilden aanschaffen of gebruiken. Veel tools die ons interessant leken, waren vaak alleen voor de Amerikaanse markt gemaakt. Dit terwijl de expertise van Employes op de Nederlandse markt ligt. Het is een prettig gevoel dat alles veilig, compleet en snel wordt verwerkt bij alle instanties. Het feit dat je met een paar clicks al kan starten trok ons over de streep. Geen gedoe meer.”'
    }, {
      image: yummygum_4
    }, {
      title: 'Wat heeft Employes voor jou en je werknemers betekend?',
      description: 'Vince: “Minder extra kosten bij onze boekhouder, sneller salarisadministratie verwerken met minder moeite en duidelijke overzichten. Onze werknemers kunnen gemakkelijk inloggen om al hun gegevens te bekijken, te downloaden, en verlof aan te vragen. Zodra je Employes een paar maandjes gebruikt denk je achteraf: “Hoezo gebruikten we dit niet eerder!?”.'
    }, {
      quote: '“Met Employes heb je in een paar clicks je volledige salarisadministratie geregeld, dat is zo’n fijn gevoel.”'
    }, {
      title: 'Tot slot',
      description: 'Vince eindigde het gesprek met een suggestie: “Houd het momentum van de verbeteringen van het platform en de ongekend goede klantenservice vast, dan kan en blijf ik Employes van harte aanraden!” Daar gaan we natuurlijk voor!'
  }],
  otherClientStories: [{
    name: 'Ruben Paijens',
    functionTitle: 'Mede-oprichter, Bibbr',
    companyLogo: '',
    description: 'Ik vind het vooral fijn dat jullie betrokken zijn.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: bibbr_2,
    quote: 'Ik vind het vooral fijn dat jullie betrokken zijn en makkelijk te bereiken zijn.',
    link: '/klanten/bibbr/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Ilse van Nierop',
    functionTitle: 'HR-manager, Avy',
    companyLogo: '',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: avy_2,
    quote: 'Ik kan onze salarisadministratie nu doen binnen 10 minuten',
    link: '/klanten/avy/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Kevin van der Veer',
    functionTitle: 'Mede-oprichter, WoodWatch',
    description: 'Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel.',
    portraitImage: woodwatch_2,
    portraitImageSmall: woodwatch_2,
    quote: 'Payroll, dat kost me gewoon teveel.',
    internallink: true,
    link: '/klanten/woodwatch/',
    btnText: 'Lees ervaring&nbsp;→'
  }]
}


const klantverhaalTemplate = ({location}) => (
  <Layout location={location}>
    <main className="clientstory animated fadeInPage">

      <Helmet>
        <title>Interview met Vince Schwidder van Yummygum | Employes</title>
        <meta name="description" content="Vince aan het woord over hun salarisadministratie: Salaris en verlof regelen is nu makkelijk, inzichtelijk en geautomatiseerd." />
        <meta itemprop="name" content="Interview met Vince Schwidder van Yummygum| Employes" />
        <meta itemprop="description" content="Vince aan het woord over hun salarisadministratie: Salaris en verlof regelen is nu makkelijk, inzichtelijk en geautomatiseerd."/>
      </Helmet>

      <Helmet>
        <title>Interview met Vince Schwidder van Yummygum | Employes</title>
        <meta name="description" content="Vince aan het woord over hun salarisadministratie: Salaris en verlof regelen is nu makkelijk, inzichtelijk en geautomatiseerd." />
        <meta itemprop="name" content="Interview met Vince Schwidder van Yummygum | Employes" />
        <meta itemprop="description" content="Vince aan het woord over hun salarisadministratie: Salaris en verlof regelen is nu makkelijk, inzichtelijk en geautomatiseerd."/>
        <meta itemprop="image" content="https://employes.nl/static/meta-yummygum-2.jpg"/>

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://employes.nl/klanten/yummygum/"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Interview met Vince Schwidder van Yummygum | Employes"/>
        <meta property="og:description" content="Vince aan het woord over hun salarisadministratie: Salaris en verlof regelen is nu makkelijk, inzichtelijk en geautomatiseerd."/>
        <meta property="og:image" content="https://employes.nl/static/meta-yummygum-2.jpg"/>

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Interview met Vince Schwidder van Yummygum | Employes"/>
        <meta name="twitter:description" content="Vince aan het woord over hun salarisadministratie: Salaris en verlof regelen is nu makkelijk, inzichtelijk en geautomatiseerd."/>
        <meta name="twitter:image" content="https://employes.nl/static/meta-yummygum-2.jpg"/>

      </Helmet>

      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid between align-middle">
            <div className="col-6 no-pad">
              <h2>{ dummyKlantVerhaal.pageTitle }</h2>
              <p className="streamer large margin-l-bottom">{ dummyKlantVerhaal.pageStreamer }</p>
              <span className="badge green">Bedrijfsgrootte: <b>{ dummyKlantVerhaal.useCase }</b></span>
            </div>
            <ClientCard className="col-6 no-pad no-logo" person={dummyKlantVerhaal.person} isSmall={(window.innerWidth < 768)}/>
          </div>
        </div>
      </header>
      <section className="up">
        <div className="clientstory-content padding-xl">
            {dummyKlantVerhaal.content.map((section, idx) => {
              const firstObjectKey = Object.keys(section)[0]
              const isImage = (firstObjectKey === 'image')
              const { title, description } = section

              return (
                <div className="paragraph margin-m-bottom" key={idx}>
                  <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                    <div className="grid yg center">
                      <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                      {{
                        title: (
                          <div className="text">
                            <h4>{ title }</h4>
                            <p className="large" dangerouslySetInnerHTML={ { __html: description } }></p>
                          </div>
                        ),
                        quote: (
                          <div className="client-quote">
                            “Met Employes heb je in een paar clicks je hele salarisadministratie geregeld, dat is zo’n fijn gevoel.”
                          </div>
                        ),
                        image: (
                          <img src={section.image} alt="klant van Employes"/>
                        )
                      }[firstObjectKey]}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="clientstory-others padding-xl">
          <div className="container-md">
            <div className="grid yg center text-center margin-l-bottom">
              <div className="col-12">
                <h6 className="eyebrow">Ervaringen</h6>
                <h2 className="no-margin">Andere klantverhalen</h2>
              </div>
            </div>
            <div className="grid yg">
              {dummyKlantVerhaal.otherClientStories.map((otherclient, idx) => {
                const { name, functionTitle, companyLogo, portraitImage, quote } = otherclient

                return (
                  <div className="col-4" key={otherclient.name} key={idx}>
                    <ClientCard person={otherclient} isSmall={true}/>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </section>
    </main>
  </Layout>
)

export default klantverhaalTemplate
